<template>
  <div class="main login-content">
    <a-form-model :model="loginInfo" :rules="loginRules" ref="loginForm">
      <a-form-model-item prop="username">
        <a-input size="large" placeholder="用户名/手机/邮箱" v-model="loginInfo.username">
          <a-icon slot="prefix" type="user"></a-icon>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="password">
        <a-input-password size="large" placeholder="密码" v-model="loginInfo.password">
          <a-icon slot="prefix" type="lock"></a-icon>
        </a-input-password>
      </a-form-model-item>
      <a-row>
        <a-col :span="16">
          <a-form-model-item prop="code">
            <a-input size="large" placeholder="验证码" v-model="loginInfo.code" @keypress.native.enter="onSubmitLogin">
              <a-icon slot="prefix" type="mail"></a-icon>
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <div style="border: 1px solid #dedede; text-align: center; background-color: #fff; border-left: 0">
            <img
              @click="changeCode"
              :src="codeSrc"
              title="看不清,换一张"
              alt="看不清,换一张"
              style="width: auto; height: 38px"
            />
          </div>
        </a-col>
      </a-row>
      <a-form-model-item>
        <a-button class="dl-btn" size="large" :loading="loading" type="primary" block @click="onSubmitLogin"
          >登录</a-button
        >
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codeSrc: '',
      loading: false,
      loginInfo: {
        username: '',
        password: '',
        code: '',
      },
      serverCode: '',
      loginRules: {
        username: [
          {
            required: true,
            message: '请输入用户名/手机号/邮箱',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
          {
            len: 5,
            message: '验证码长度不正确',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value != this.serverCode) {
                callback(new Error('输入验证码不正确'))
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.changeCode()
  },
  methods: {
    /**
     * 刷新验证码
     */
    changeCode() {
      this.axios
        .get(`/api/base/auth/createCode`)
        .then((res) => {
          this.codeSrc = `data:image/png;base64,${res.body.verCodeImg}`
          this.serverCode = res.body.verCode
        })
        .catch((err) => {})
    },
    onSubmitLogin() {
      if (this.loading) {
        return false
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('Login1', this.loginInfo)
            .then((res) => {
              this.$router.push('/dashboard/analysis')
              this.$store.dispatch('common/getInitBill')
              this.$store.dispatch('common/getInitConfirm')
              // 登录后吊起版本弹窗
            })
            .catch((err) => {})
            .finally(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.login-content {
  margin-left: 10% !important;
}

.dl-btn {
  background-color: #4963f3 !important;
  border-color: #4963f3 !important;
  color: white;
}
</style>
